const LOCATIONS = [
  'Grand Central Terminal',
  'Penn Station',
  '.',
  'A',
  'Albertson',
  'Amagansett',
  'Amityville',
  'Ansonia',
  'Appalachian Trail',
  'Ardsley on Hudson',
  'Atlantic Terminal',
  'Auburndale',
  'B',
  'Babylon',
  'Baldwin',
  'Bay Shore',
  'Bayside',
  'Beacon',
  'Beacon-Falls',
  'Beadford Hills',
  'Bellrose',
  'Bellmore',
  'Bellport',
  'Bethel',
  'Bethpage',
  'Botanical Garden',
  'Branchville',
  'Breakneck Ridge',
  'Brentwood',
  'Brewster',
  'Bridgehampton',
  'Bridgeport',
  'Broadway',
  'Bronxville',
  'C',
  'Cannondale',
  'Carle Place',
  'Cedarhurst',
  'Central Islip',
  'Centre Avenue',
  'Chappaqua',
  'Cold Spring',
  'Cold Spring Harbor',
  'Copiague',
  'Cortlandt',
  'Cos Cob',
  'Country Life Press',
  'Crestwood',
  'Croton Falls',
  'Corton-Harmon',
  'D',
  'Danbury',
  'Darien',
  'Deer Park',
  'Derby-Shelton',
  'Dobbs Ferry',
  'Douglaston',
  'Dover Plains',
  'E', 'East Hampton',
  'East New York',
  'East Norwalk',
  'East Rockaway',
  'East Wiliston',
  'F',
  'Fairfield',
  'Fairfield Metro',
  'Far Rockaway',
  'Farmingdale',
  'Fleetwood',
  'Floral Park',
  'Flushing Main Street',
  'Fordham',
  'Forest Hills',
  'Freeport',
  'G',
  'Garden City',
  'Garrison',
  'Gibson',
  'Glen Cove',
  'Glen Head',
  'Geln Street',
  'Glenbrook',
  'Glenwood',
  'Goldens Bridge',
  'Great Neck',
  'Great River',
  "Green's Farms",
  'Greenlawn',
  'Greenvale',
  'Greenwich',
  'Greystone',
  'H',
  'Hampton Bays',
  'Harlem - 125th Street',
  'Harlem Valley - Wingdale',
  'Harrison',
  'Hartsdale',
  'Hawthorne',
  'Hempstead',
  'Hempstead Gardens',
  'Hewlett',
  'Hicksville',
  'Hollis',
  'Hunterspoint Avenue',
  'Huntington',
  'I',
  'Inwood',
  'Irvington',
  'Island Park',
  'Islip',
  'J',
  'Jamaica',
  'K',
  'Katonah',
  'Kew Gardens',
  'Kings Park',
  'L',
  'Lakeview',
  'Larchmont',
  'Laurelton',
  'Lawrence',
  'Lindenhurst',
  'Little Neck',
  'Locust Manor',
  'Long Beach',
  'Long Island City',
  'Ludlow',
  'Lynbrook',
  'M',
  'Malverne',
  'Mamaroneck',
  'Manhasset',
  'Manitou',
  'Marble Hill',
  'Massapequa',
  'Massapequa Park',
  'Mastic-Shriley',
  'Mattituck',
  'Medford',
  'Melrose',
  'Merillon Avenue',
  'Merrick',
  'Merrit 7',
  'Mets - Willets Point',
  'Milford',
  'Mineola',
  'Montauk',
  'Morris Heights',
  'Mount Kisco',
  'Mount Pleasant',
  'Mount Vernon East',
  'Mount Vernon West',
  'Murray Hill',
  'N',
  'NH State St',
  'Nassau Boulevard',
  'Naugatuck',
  'New Canaan',
  'New Hamburg',
  'New Haven',
  'New Hyde Park',
  'New Rochelle',
  'Noroton Heights',
  'North White Plains',
  'Northport',
  'Nostrand Avenue',
  'O',
  'Oakdale',
  'Oceanside',
  'Old Greenwich',
  'Ossining',
  'Oyster Bay',
  'P',
  'Patchogue',
  'Patterson',
  'Pawling',
  'Peekskill',
  'Pelham',
  'Philipse Manor',
  'Pinelawn',
  'Plandome',
  'Pleasantville',
  'Port Chester',
  'Port Jefferson',
  'Port Washington',
  'Poughkeepsie',
  "Purdy's",
  'Q',
  'Queens Village',
  'R',
  'Redding',
  'Riverdale',
  'Riverhead',
  'Riverside',
  'Rockville Centre',
  'Ronkonkoma',
  'Rosedale',
  'Roslyn',
  'Rowayton',
  'Rye',
  'S',
  'Saint Albans',
  'Saint James',
  'Sayville',
  'Scarborough',
  'Scarsdale',
  'Sea Cliff',
  'Seaford',
  'Seymour',
  'Smithtown',
  'South Norwalk',
  'Southampton',
  'Southeast',
  'Southold',
  'Southport',
  'Speonk',
  'Springdale',
  'Spuyten Duyvil',
  'Stamford',
  'Stewart Manor',
  'Stony Brook',
  'Stratford',
  'T',
  'Talmadge Hill',
  'Tarrytown',
  'Tenmile River',
  'Tremont',
  'Tuckahoe',
  'U',
  'University Heights',
  'V',
  'Valhalla',
  'Valley Stream',
  'W',
  'Wakefield',
  'Wantagh',
  'Wassaic',
  'Waterbury',
  'West Haven',
  'West Hempstead',
  'Westbury',
  'Westhampton',
  'Westport',
  'Westwood',
  'White Plains',
  'Williams Bridge',
  'Wilton',
  'Woodlawn',
  'Woodmere',
  'Woodside',
  'Wyandanch',
  'Y',
  'Yankees - E. 153rd Street',
  'Yaphank',
  'Yonkers'
]

export default LOCATIONS;